.main-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid #373737;
    background: #373737;
}

.main-button:hover {
    background: #505050;
    border-color: #505050;
    cursor: pointer;
}
.inside-button {
    text-decoration: none;
    background: None;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

@media screen and (min-width: 1000px) {
    .main-button {
        width: 140px;
        height: 50px;
        border-radius: 8px;
        margin-right: 7px;
    }

    .button-text {
        font-size: 20px;
        padding: 5px;
        background: none;
    }

    .material-icons {
        background: None;
        font-size: 30px;
    }

    img {
        height: 27px;
        width: 27px;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 999px) {
    .main-button {
        width: 110px;
        height: 40px;
        border-radius: 5px;
        margin-right: 4px;
    }

    .button-text {
        font-size: 15px;
        padding: 5px;
        background: none;
    }

    .material-icons {
        background: None;
        font-size: 30px;
    }

    img {
        height: 22px;
        width: 22px;
        background: None;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 400px) {
    .main-button {
        width: 110px;
        height: 40px;
        border-radius: 5px;
        margin-right: 4px;
    }

    .button-text {
        font-size: 18px;
        padding: 0;
        background: none;
    }

    .material-icons {
        background: None;
        font-size: 25px;
    }

    img {
        height: 19px;
        width: 19px;
        background: None;
        align-items: center;
        justify-content: center;
    }
}